<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>App Name / App ID</label>
          <b-form-input
            v-model="appListFilter.app_id"
            placeholder="App Name / App ID"
            @keydown.enter.native="doSearch"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Merchant</label>
          <v-select
            v-model="appListFilter.merchant_no"
            :options="merchantNoOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Method</label>
          <v-select
            key="type_name"
            v-model="methodFilter"
            label="type_desc"
            :options="methodOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.type_name"
            multiple
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2  mt-1"
        >
          <label>Channel</label>
          <v-select
            v-model="channelFilter"
            :options="channelOptions"
            :clearable="false"
            :disabled="channelDisabled"
            class="w-100"
            :reduce="val => val.value"
            multiple
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2  mt-1"
        >
          <label>Region</label>
          <v-select
            v-model="appListFilter.region"
            :options="regionOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-1"
        >
          <label>Industry</label>
          <v-select
            v-model="appListFilter.industry"
            :options="industryOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-1"
        >
          <label>Status</label>
          <v-select
            v-model="appListFilter.status"
            :options="statusOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-1"
        >
          <label>Forter</label>
          <v-select
            v-model="appListFilter.is_forter"
            :options="forterOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-1"
        >
          <label>App Type</label>
          <v-select
            v-model="appListFilter.review_level"
            :options="reviewLevelOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-1 "
        >
          <label />
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
          <b-button v-if="$can('write', 'Merchants.AppDownload')"
                    class="mt-2 ml-2"
                    variant="primary"
                    @click="doDownload"
          >
            Download
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showAlert } from '@/libs/tool'
import applistJs from './applist'

const {
  fetchIndustryOptions,
  fetchCommonOptions,
  fetchMerchants,
  fetchChannelFilters,
  fetchPayChannelListDetail,
} = applistJs()

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
  },
  data() {
    return {
      appListFilter: {
        app_id: '',
        app_name: '',
        merchant_no: 'All',
        region: 'All',
        industry: 'All',
        status: '',
        review_level: '', // App Type
        is_forter: '',
      },
      merchantNoOptions: [],
      methodFilter: 'All',
      methodOptions: [],
      channelFilter: 'All',
      channelOptions: [],
      payChannelList: [],
      regionOptions: [],
      industryOptions: [],
      statusOptions: [
        { value: '', label: 'All' },
        { value: '1', label: 'enable' },
        { value: '0', label: 'disable' },
      ],
      forterOptions: [
        { value: '', label: 'All' },
        { value: '1', label: 'Y' },
        { value: '0', label: 'N' },
      ],
      reviewLevelOptions: [
        { value: '', label: 'All' },
        { value: '1', label: 'All Pass' },
        { value: '3', label: 'General' },
      ],
    }
  },
  computed: {
    channelDisabled() {
      return this.methodFilter === 'All' || this.methodFilter.type_id === ''
    },
    // limitReached() {
    //   if (this.methodFilter.length > 5) {
    //     return true
    //   }
    //   return false
    // },
  },
  watch: {
    methodFilter: function resetChannel(method) {
      const defaultChannel = { label: 'All', value: 'All' }
      const channels = []
      channels.push(defaultChannel)
      if (method !== 'All') {
        if (method.length !== 1 && method.indexOf('All') !== -1) {
          if (method.indexOf('All') === method.length - 1) {
            method.splice(0, method.length - 1)
            this.methodFilter = method
          } else {
            method.forEach((item, index) => {
              if (item === 'All') {
                method.splice(index, 1)
              }
            })
            this.methodFilter = method
          }
        }
        this.payChannelList.forEach(item => {
          method.forEach(ele => {
            if (ele === item.type_name) {
              const cl = {
                label: item.cname,
                value: item.cid,
              }
              channels.push(cl)
            }
          })
        })
      }
      this.channelOptions = channels
      this.channelFilter = 'All'
    },
    channelFilter: function channelFilters(channel) {
      if (channel !== 'All') {
        if (channel.length !== 1 && channel.indexOf('All') !== -1) {
          if (channel.indexOf('All') === channel.length - 1) {
            channel.splice(0, channel.length - 1)
            this.channelFilter = channel
          } else {
            channel.forEach((item, index) => {
              if (item === 'All') {
                channel.splice(index, 1)
              }
            })
            this.channelFilter = channel
          }
        }
      }
    },
  },
  created() {
    fetchChannelFilters(this, filters => {
      const newElement = {
        type_id: '',
        type_name: 'All',
        type_desc: 'All',
      }
      filters.methods.splice(0, 1, newElement)
      this.methodOptions = filters.methods
      this.methodFilter = 'All'
    })
    fetchPayChannelListDetail(this, list => {
      this.payChannelList = list
    })
    this.fetchIndustry()
    this.fetchCommon()
    this.fetchMerchants()
  },
  methods: {
    genParams() {
      const params = {
        app_id: this.appListFilter.app_id,
        merchant_no: this.appListFilter.merchant_no,
        region: this.appListFilter.region,
        industry: this.appListFilter.industry,
        status: this.appListFilter.status,
        review_level: this.appListFilter.review_level, // App Type
        channels: this.channelFilter === 'All' ? [] : this.channelFilter,
        methods: this.methodFilter === 'All' ? [] : this.methodFilter,
        is_forter: this.appListFilter.is_forter,
        page_num: 1,
      }
      if (params.merchant_no === 'All') {
        params.merchant_no = ''
      }
      if (params.region === 'All') {
        params.region = ''
      }
      if (params.industry === 'All') {
        params.industry = ''
      }
      return params
    },
    doSearch() {
      const params = this.genParams()
      this.$emit('search', params)
    },
    doDownload() {
      const params = this.genParams()
      this.$emit('download', params)
    },
    fetchIndustry() {
      const that = this
      if (that.industryOptions.length > 0) {
        return
      }
      fetchIndustryOptions(
        industry => {
          that.industryOptions = industry
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Fetch industry options fail with ${fail}`)
        },
      )
    },
    fetchCommon() {
      const that = this
      if (that.regionOptions.length > 0) {
        return
      }
      fetchCommonOptions(
        res => {
          that.regionOptions = []
          that.regionOptions.push({ value: 'All', label: 'All' })
          res.countries.forEach(value => {
            that.regionOptions.push({ value: value.region_code, label: value.region_name })
          })
        },
        fail => {
          showAlert(this, 'warning', 'Fail!', `Fetch common options fail with ${fail}`)
        },
      )
    },
    fetchMerchants() {
      if (this.merchantNoOptions.length > 0) {
        return
      }
      fetchMerchants(
        merchants => {
          this.merchantNoOptions = merchants
        },
        () => {},
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
