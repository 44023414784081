<template>

  <div>
    <!-- Filters -->
    <app-list-filters
      @search="search"
      @download="download"
    />

    <div>
      <!-- Add New Sidebar -->
      <app-list-sidebar
        :is-app-sidebar-active.sync="isAppSidebarActive"
        :data="appInfo"
        @updated="search"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          class="position-relative"
          responsive
          :fields="tableFields"
          :items="apps"
          primary-key="app_id"
          show-empty
          striped
          :borderless="false"
          empty-text="No matching records found"
        >

          <!-- Column: APP -->
          <template #cell(app)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.icon_url"
                  :text="data.item.app_name"
                  variant="primary"
                />
              </template>
              {{ data.item.app_name }}
              <small class="text-muted">@{{ data.item.app_id }}</small>
            </b-media>
          </template>

          <!-- Column: Merchant -->
          <template #cell(merchant)="data">
            <b-media vertical-align="center">
              {{ data.item.merchant_name }}
              <small class="text-muted">@{{ data.item.merchant_no }}</small>
            </b-media>
          </template>

          <!-- Column: Region -->
          <template #cell(region)="data">
            {{ data.item.region_name }}
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ resolveStatusText(data.item.status) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="showDetail(data.item)"
              />
            </div>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalApps"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 mr-3"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BPagination, BBadge, BOverlay,
} from 'bootstrap-vue'
import { showAlert } from '@/libs/tool'
import useJwt from '@/auth/jwt/useJwt'
import AppListSidebar from './AppListSidebar.vue'
import AppListFilters from './AppListFilters.vue'
import applistJs from './applist'

const {
  // fetchMerchantAppsDetail
  filterAppPage,
  downloadApps,
} = applistJs()

export default {
  components: {
    AppListSidebar,
    AppListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BBadge,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      merchantNo: '102320170519', // this.$route.query.merchant_no
      merchantName: this.$route.params.merchant_name,
      apps: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      searchRequest: {},
      perPage: 10,
      tableFields: [
        'app',
        'merchant',
        'region',
        'industry',
        { key: 'create_time', label: 'REGISTER DATE' },
        { key: 'forter_site', label: 'FORTER SITE' },
        'status',
        'actions',
      ],
      usersTableFields: [
        'nick_name',
        'real_name',
        'actions',
      ],
      currentPage: 1,
      totalApps: 0,
      filter: null,
      filterOn: ['app_id', 'app_name', 'index_url', 'contact_email'],

      isAppSidebarActive: false,
      appInfo: {},
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  methods: {
    resolveStatusVariant(status) {
      if (status === '0') return 'warning'
      if (status === '1') return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === '0') return 'Disabled'
      if (status === '1') return 'Enabled'
      return 'Unknown'
    },

    showDetail(appDetail) {
      this.appInfo = appDetail
      this.isAppSidebarActive = true
    },
    search(data) {
      const params = {
        access_token: useJwt.getToken(),
        data,
      }
      this.searchRequest = data
      this.loading = true
      filterAppPage(
        apps => {
          this.loading = false
          this.apps = apps.list
          this.totalApps = apps.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalApps - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalApps
          this.dataMeta.of = this.totalApps
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
        params,
      )
    },
    download(params) {
      this.loading = true
      this.searchRequest = params
      downloadApps(this, this.searchRequest)
    },
  },
}
</script>
